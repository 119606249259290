
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { fixDate, fixPrice, stringifySafe } from '@/utils/general'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Evaluation, Offer, Quota } from '@/entities/loans'
  import { Form } from '@/entities/public/Resource/metadata'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import dayjs from 'dayjs'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { AutoResume, FieldTitle, GFiles, LinkedAuto, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class EvaluationForm extends GForm {
  evaluation: Evaluation = plainToInstance(Evaluation, {})

  declare $refs: {
    form: HTMLFormElement
  };

  title = ''
  showDetail = false
  carLoaded = false

  formData = {
    resp: null,
    approvedCondition: null,
    rejectionReason: null,
    approvalRequirement: null,
    businessApproach: null,
    quotas: [],
    comment: '',
  }

  fields = {
    respTitle: {
      properties: {},
    },
    businessApproach: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    quotas: {
      properties: {},
      rules: this.fieldRequired,
    },
    approvalRequirement: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    resp: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    approvedCondition: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    rejectionReason: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
  }

  metadata = {}
  metadataCollection = {}

  async mounted () {
    await this.setMetadata()
    const { uid, id, model, title, metadataCollection } = this

    if ((!isNaN(uid) && (model === 'Evaluation' || model === '')) || !isNaN(id)) {
      const evaluationId = id || uid
      await this.getEvaluationInfo(evaluationId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(this.evaluation?.id))
    }
    this.setTheBackup()
  }

  async getEvaluationInfo (id) {
    const evaluation = await this.fetchData({
      query: { name: 'fetch', model: 'Evaluation', params: { id } },
      force: true,
    })

    const financing = await this.fetchData({
      query: { name: 'fetch', model: 'Financing', params: { id: evaluation.financing.id } },
      force: true,
    })

    this.evaluation = evaluation
    this.evaluation.financing = financing
    this.formData.businessApproach = evaluation.businessApproach
    this.formData.approvalRequirement = evaluation?.requirements?.map(requirement => requirement.requirementType)
    this.formData.resp = !evaluation.status.isPending ? evaluation.status : null
    this.formData.quotas = evaluation?.offers || []
    this.formData.comment = evaluation.comment

    this.formData.approvedCondition = evaluation.conditions.map(condition => {
      return {
        ...condition.conditionType,
      }
    })
    this.fields.businessApproach.items = await this.fetchData({
      query: { name: 'find', model: 'BusinessApproach' },
      filter: { products: { agreement_product: { agreement: { id: { _eq: evaluation.agreement.id } } } } },
    })

    await this.setDetails()
  }

  async setMetadata () {
    const { metadata } = this.getForm('Evaluation', 'financer_evaluation')
    const { form, fields } = metadata as Form

    this.metadataCollection = metadata
    this.title = form.title
    this.fields.resp.properties = fields.resp.properties
    this.fields.approvedCondition.properties = fields.approvedCondition.properties
    this.fields.rejectionReason.properties = fields.rejectionReason.properties
    this.fields.approvalRequirement.properties = fields.approvalRequirement.properties
    this.fields.businessApproach.properties = fields.businessApproach.properties
    this.fields.quotas.properties = fields.quotas.properties
    this.fields.respTitle.properties = fields.respTitle.properties

    this.fields.resp.items = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _nin: ['closed', 'pending', 'preapproved'] } } }] },
    })
    this.fields.rejectionReason.items = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { status: { _and: [{ status: { name: { _eq: 'rejected' } } }, { process: { table_name: { _eq: 'evaluation' } } }] } },
    })
    this.fields.approvedCondition.items = await this.fetchData({
      query: { name: 'find', model: 'ConditionType' },
    })

    this.fields.approvalRequirement.items = await this.fetchData({
      query: { name: 'find', model: 'RequirementType' },
    })
  }

  async setStock () {
    const { evaluation } = this
    const auto = await this.fetchData({
      query: { name: 'find', model: 'Auto' },
      filter: { deals: { id: { _eq: evaluation.financing.saleOrder.deal.id } } },
      force: true,
    })

    this.evaluation.financing.saleOrder.deal.auto = auto[0]
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return
    const quotaForm = 'quotaForm' in backup && backup.quotaForm

    if ('evaluationForm' in backup && backup.evaluationForm) {
      this.formData = backup.evaluationForm
    }

    if (quotaForm) {
      const offer = plainToInstance(Offer, {})
      const { term, monthlyRate, futureValue, fee, quotaValue, specialQuota, date, insurance } = quotaForm
      offer.startPayment = dayjs(fixDate(date))
      offer.variant = term
      offer.rate = monthlyRate
      offer.coverage = futureValue
      offer.fee = fee
      offer.insurance = insurance
      const quota1 = plainToInstance(Quota, {})
      quota1.amount = quotaValue
      quota1.characteristic = term.isRegular
      const quota2 = plainToInstance(Quota, {})
      quota2.amount = specialQuota
      quota2.characteristic = term.isSpecial
      offer.quotas = [quota1, quota2].filter(_ => _.amount)

      this.formData.quotas.push(offer)
    }
  }

  saveBackup () {
    const { backup, formData, evaluation } = this
    if (backup) {
      backup.evaluationForm = formData
      backup.evaluation = evaluation
      this.setBackup(backup)
    } else {
      this.setBackup({ evaluationForm: formData, evaluation })
    }
  }

  validateFields () {
    const { formData, isApprovedCondition, isApproved } = this

    if (!formData.approvedCondition?.length && isApprovedCondition) {
      this.formData.approvedCondition = null
      return true
    }

    if (!formData.approvalRequirement?.length && isApproved) {
      this.formData.approvalRequirement = null
      return true
    }

    if (!formData.quotas?.length && isApproved) {
      this.formData.quotas = null
      return true
    }

    if (!formData.businessApproach && isApproved) {
      this.formData.businessApproach = null
      return true
    }

    return false
  }

  async send () {
    const { formData, isApprovedCondition } = this

    if (!this.$refs.form.validate() || this.validateFields()) {
      return
    }
    this.loadingForm = true

    const {
      evaluation: { id }, isRejectionReason,
    } = this

    if (id) {
      const responsible = await this.fetchData({
        query: { name: 'find', model: 'ResponsibleAgreement' },
        filter: { responsible: { user: { id: { _eq: this.user.id } } } },
      })

      if (isRejectionReason) {
        await this.pushRejectionReason(id, formData, responsible)
      } else if (isApprovedCondition) {
        await this.pushApprovedCondition(id, formData, responsible)
      } else {
        await this.pushApproved(id, formData, responsible)
      }
    }

    await this.close()
    this.loadingForm = false
  }

  async pushRejectionReason (id, formData, responsible) {
    await this.pushData({
      model: 'Evaluation',
      fields: {
        id,
        id_closing_reason: formData.rejectionReason.id,
        id_process_status: formData.resp.id,
        id_responsible_agreement: responsible?.[0]?.id,
        comment: formData.comment,
      },
    })
  }

  async pushApprovedCondition (id, formData, responsible) {
    const { evaluation } = this

    await this.pushData({
      model: 'Evaluation',
      fields: {
        id,
        id_process_status: formData.resp.id,
        id_responsible_agreement: responsible[0].id,
        comment: formData.comment,
      },
    })

    const { conditions } = evaluation
    const approvedCondition = formData.approvedCondition.filter(approvCondition => !conditions.some(condition => condition.conditionType.id === approvCondition.id))
    if (approvedCondition?.length) {
      await Promise.all(approvedCondition.map(item => this.pushData({
        model: 'EvaluationCondition',
        fields: { id_evaluation: id, id_condition_type: item.id },
      })))
    }
  }

  async pushApproved (id, formData, responsible) {
    const { evaluation, backup } = this

    await this.pushData({
      model: 'Evaluation',
      fields: {
        id,
        id_process_status: formData.resp?.id,
        id_business_approach: formData?.businessApproach?.id,
        id_responsible_agreement: responsible[0].id,
        comment: formData.comment,
      },
    })

    const offers = formData.quotas.filter(quota => !evaluation?.offers?.some(offer => offer?.id === quota?.id))
    const newOffers = []
    await Promise.all(offers.map(async offer => {
        const fields = {
          id_evaluation: id,
          id_variant: offer.variant.id,
          rate: offer.rate,
          coverage: offer.coverage,
          start_payment: offer.startPayment.format('YYYY-MM-DD HH:mm:ss'),
          fee: offer.fee,
          quotas: {
            data: offer.quotas.map(quota => {
              return { amount: quota.amount, id_quota_characteristic: quota?.characteristic?.id }
            }).filter(_ => _.amount),
          },
          products: {
            data: offer.insurance.map(insurance => {
              return { id_agreement_product: insurance.id }
            }),
          },
        }
        const getNewOffer = await this.pushData({
          model: 'Offer',
          fields,
        })
        newOffers.push(getNewOffer)
      }
    ))

    if (newOffers.length) {
      if (backup) {
        backup.newQuotas = newOffers
        this.setBackup(backup)
      } else {
        this.setBackup({ newQuotas: newOffers })
      }
    }

    const requirements = formData.approvalRequirement.filter(requirement => !evaluation?.requirements?.some(eRequirement => eRequirement.requirementType.id === requirement.id))

    if (requirements?.length) {
      await Promise.all(requirements.map(item => this.pushData({
        model: 'EvaluationRequirement',
        fields: { id_evaluation: id, id_requirement_type: item.id },
      })))
    }
  }

  async setDetails () {
    const { metadataCollection, evaluation } = this

    evaluation.financing.applicant.laborData = await this.fetchData({
      query: { name: 'find', model: 'LaborData' },
      filter: {
        _and: [
          { person: { id: { _eq: evaluation.financing.applicant.id } } },
          { active: { _eq: true } }],
      },
      force: true,
    })

    this.metadata = {
      data: evaluation,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(evaluation.id)
    await this.setStock()
  }

  get bindResp () {
    const { fields: { resp } } = this

    if (!resp) return null
    return { ...resp.properties, items: resp.items }
  }

  get isApprovedCondition () {
    const { formData } = this

    return formData.resp?.isConditioned
  }

  get isRejectionReason () {
    const { formData } = this

    return formData.resp?.isRejected
  }

  get disabledFormApproved () {
    const { evaluation } = this

    return Boolean(evaluation?.status?.isApproved)
  }

  get isApproved () {
    const { formData } = this

    return formData.resp?.isApproved
  }

  get bindApprovedCondition () {
    const { fields: { approvedCondition } } = this

    if (!approvedCondition) return null
    return { ...approvedCondition.properties, items: approvedCondition.items }
  }

  get bindApprovalRequirement () {
    const { fields: { approvalRequirement } } = this

    if (!approvalRequirement) return null
    return { ...approvalRequirement.properties, items: approvalRequirement.items }
  }

  get bindBusinessApproach () {
    const { fields: { businessApproach } } = this

    if (!businessApproach) return null
    return { ...businessApproach.properties, items: businessApproach.items }
  }

  get bindRejectionReason () {
    const { fields: { rejectionReason } } = this

    if (!rejectionReason) return null
    return { ...rejectionReason.properties, items: rejectionReason.items }
  }

  get change () {
    const { formData, carLoaded } = this

    return stringifySafe([formData, carLoaded])
  }

  get isBusinessApproach () {
    const { formData } = this

    return Boolean(formData.businessApproach?.id)
  }

  get disabledConditionedFields () {
    const { evaluation } = this

    return Boolean(evaluation?.conditions?.length)
  }

  get stock () {
    const { evaluation } = this

    return evaluation?.financing?.saleOrder?.deal?.stock
  }

  @Watch('formData.resp', { immediate: true })
  onRespChange (val) {
    if (val?.isRejectionReason) {
      this.formData.approvedCondition = null
    } else if (val?.isConditioned) {
      this.formData.rejectionReason = null
    }
  }

  async openQuota () {
    const { backup, formData, evaluation: { id } } = this
    if (!backup) {
      this.setBackup({ evaluationForm: formData })
    } else {
      const back = {
        evaluationForm: backup?.evaluationForm || formData,
      }
      this.setBackup(back)
    }
    await this.$router.push({
      name: 'generic-quota',
      params: {
        model: 'Evaluation',
        uid: id ? id?.toString() : 'create',
        id: 'create',
      },
    })
  }

  @Debounce()
  photoLoaded () {
    this.carLoaded = !this.carLoaded
  }
  }
